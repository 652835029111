import React, { getGlobal, useGlobal } from "reactn";
import { Navigate, useLocation } from "react-router-dom"; // Removed Route import
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader, useGlobalTheme } from "utils/global";
import { authAPI, userAPI } from "utils/portal-apis";

import { defaultSettings, getMenuRoutes, RightContent } from "./common";
import { useEffect, useState } from "react";
import styled from "styled-components";

// Adjust the StyledProLayout styling if necessary for the top bar layout
const StyledProLayout = styled(ProLayout)`
max-width: none !important;
width: 100vw !important;
padding-left: 0 !important;
padding-right: 0 !important;
`;

function PrivateRoute({ children, noHeader = false }) {
  const { loading } = useGlobalLoader();
  const [authenticated, setAuthenticated] = useState(true);
  const [isLive, setIsLive] = useGlobal("isLive");
  const theme = useGlobalTheme();
  let location = useLocation();

  useEffect(() => {
    userAPI.refreshUser().then((result) => {
      setAuthenticated(!!result && !!result._id);
    });
  }, []);

  if (authenticated && authAPI.isLoggedIn()) {
    const user = getGlobal();
    if (user.show_portal_onboarding) {
      localStorage.setItem("redirectURL", location.pathname);
      return <Navigate to="/auth/onboarding" />;
    }
    return (
      <GlobalLoader loading={loading}>
        <StyledProLayout
          {...Object.assign(defaultSettings, { logo: theme.logo, layout: 'top', headerRender: noHeader ? false : undefined })}
          route={isLive ? getMenuRoutes(true) : getMenuRoutes(false)}
          rightContentRender={RightContent}
          isPublicPage={false}
          isLive={isLive}
        >
          <Layout>
            <Layout.Content>
              {children}
            </Layout.Content>
          </Layout>
        </StyledProLayout>
      </GlobalLoader>
    );
  } else {
    localStorage.setItem("redirectURL", location.pathname);
    return <Navigate to="/auth/login" />;
  }
}

export default PrivateRoute;
