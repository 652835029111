import React, { Suspense } from "reactn";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { PageLoading } from "@ant-design/pro-layout";

import { PublicRoute, PrivateRoute, AuthRoute } from "utils/routes";
import { LoaderStore, ThemeContext } from "utils/global";
import { useEffect, useState } from "react";
import { systemAPI } from "utils/portal-apis";

import { ConfigProvider, theme as antdTheme } from 'antd';
import enUS from 'antd/locale/en_US';

// Auth Imports
const AuthLogin = React.lazy(() => import("pages/Auth/Login"));
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"));
const ResetWithToken = React.lazy(() => import("pages/Auth/ResetWithToken"));
const OnboardingMain = React.lazy(() => import("pages/Auth/OnboardingMain"));
const OnboardingFirstVisit = React.lazy(() => import("pages/Auth/OnboardingFirstVisit"));
const OAuthCallback = React.lazy(() => import("pages/Auth/OAuthCallback"));

// Settings
const SettingsProfile = React.lazy(() => import("pages/Settings/Profile"));
const SettingsAccount = React.lazy(() => import("pages/Settings/Account"));
const SettingsSocial = React.lazy(() => import("pages/Settings/Social"));
const SettingsSecurity = React.lazy(() => import("pages/Settings/Security"));

// Orgs
const Orgs = React.lazy(() => import("pages/Org/List"));

// Schedule
const ScheduleChannels = React.lazy(() => import("pages/Schedule/Channels"));

// Event
const EventSingle = React.lazy(() => import("pages/Event/Single"));
const EventActiveList = React.lazy(() => import("pages/Event/Active"));

// Broadcast
const BroadcastMy = React.lazy(() => import("pages/Dashboard/My"));
const BroadcastSingle = React.lazy(() => import("pages/Broadcast/Page"));

// Experience Composer Page
const ComposerPage = React.lazy(() => import("pages/Broadcast/ComposerPage"));

// Watch
const WatchSingle = React.lazy(() => import("pages/Watch/Single"));

// Orgs
const OrgList = React.lazy(() => import("pages/Orgs"));

// Orgs
const UserList = React.lazy(() => import("pages/User/List"));
const Moderation = React.lazy(() => import("pages/Moderation"));
const ModerationList = React.lazy(() => import("pages/Moderation/List"));

// Webhooks
const Webhooks = React.lazy(() => import("pages/Webhooks"));

// Uploaded Videos CRUD
const Media = React.lazy(() => import("components/Media"));
const ViewUploadedMedia = React.lazy(() => import("components/Media/View"));

// Exception Imports
const Exception404 = React.lazy(() => import("pages/Exception/404"));

// Add this new import for the ClipsPage
const ClipsPage = React.lazy(() => import("pages/Clips"));

function App() {
  const [theme, setTheme] = useState({
    logo: "/logo_br.svg",
    color: "#6927f4",
    login_image: "/logo_br_square.svg",
    company_name: "Bleacher Report",
    description: "Bleacher Report Studio",

    colorPrimary: '#6927f4',
    primaryColor: '#6927f4', // primary color for all components
    linkColor: '#1890ff', // link color
    successColor: '#52c41a', // success state color
    warningColor: '#faad14', // warning state color
    errorColor: '#f5222d', // error state color
    fontSizeBase: '14px', // major text font size
    headingColor: 'rgba(255, 255, 255, 0.85)', // heading text color
    textColor: 'rgba(255, 255, 255, 0.65)', // major text color
    textColorSecondary: 'rgba(255, 255, 255, 0.45)', // secondary text color
    
    disabledColor: 'rgba(255, 255, 255, 0.25)', // disable state color
    borderRadiusBase: '4px', // major border radius
    borderColorBase: '#d9d9d9', // major border color
    boxShadowBase: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)', // major shadow for layers
    menuDarkColor: '#000c17', // dark color for menus
    menuDarkBg: '#001529', // background color for dark menus
    layoutHeaderBackground: '#001529', // background color for layout header
    layoutFooterBackground: '#001529', // background color for layout footer
    itemHoverBg: '#2c2c2f', // background color for item hover
    highlightColor: '#ff4d4f', // color for highlighting items
    bodyBackground: '#f0f2f5', // background color for the body

    border: '1px solid #68676D',
    border2: '1px solid #48474D',

    surfaceLevel2: '#1A1A1A',
    surfaceLevel3: '#2A2A2A',
    surfaceLevel4: '#3A3A3A',
    selectedBackground: '#2a2a2a',
    focusedSurface: '#3D3C41',
    surfaceQuiet: '#27262A'
  });

  
  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    
    setDefaultTheme();
    // eslint-disable-next-line
  }, []);

  const setDefaultTheme = () => {
    const lessVars = Object.keys(theme).reduce((acc, key) => {
      acc[`@${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`] = theme[key];
      return acc;
    }, {});
    // console.log("Less css variables:", lessVars);
    window.less
      .modifyVars(lessVars)
      .catch(e => console.log("Error updating default theme", e));
  };

  return (
    <LoaderStore>
      <ConfigProvider locale={enUS} theme={{
        algorithm: antdTheme.darkAlgorithm,
        token: theme
      }}>
      <ThemeContext.Provider value={theme}>
        <Router>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              {/* Root */}
              <Route path="/" exact element={<Navigate to="/broadcast/my" replace />} />

              {/* Auth */}
              <Route path="/auth" element={<Navigate to="/auth/login" replace />} />

              <Route path="/auth/login" element={<AuthRoute><AuthLogin /></AuthRoute>} />
              <Route path="/auth/reset" element={<AuthRoute><ResetPassword /></AuthRoute>} />
              <Route path="/auth/reset/:token" element={<AuthRoute><ResetWithToken /></AuthRoute>} />
              <Route path="/auth/onboarding" element={<AuthRoute><OnboardingMain /></AuthRoute>} />
              <Route path="/auth/onboarding/:token" element={<AuthRoute><OnboardingFirstVisit /></AuthRoute>} />
              <Route path="/login/oauth/callback" element={<AuthRoute><OAuthCallback /></AuthRoute>} />

              {/* Settings */}
              <Route path="/settings" element={<PrivateRoute><SettingsProfile /></PrivateRoute>} />
              <Route path="/settings/profile" element={<PrivateRoute><SettingsProfile /></PrivateRoute>} />
              <Route path="/settings/account" element={<PrivateRoute><SettingsAccount /></PrivateRoute>} />
              <Route path="/settings/social" element={<PrivateRoute><SettingsSocial /></PrivateRoute>} />
              <Route path="/settings/security" element={<PrivateRoute><SettingsSecurity /></PrivateRoute>} />

              {/* Developer */}
              <Route path="/webhooks" element={<PrivateRoute><Webhooks /></PrivateRoute>} />

              {/* Event */}
              {/*
                The following line is confusing and seems broken for two reasons:
                1. `/schedule/events` is a route that apparently doesn't exist.
                2. The `Navigate` component appears to break the app when it tries to redirect to an existing page.
                  2.1 This is what was breaking the `/settings` route.
              */}
              <Route path="/event" element={<PrivateRoute><Navigate to="/schedule/events" replace /></PrivateRoute>} />
              <Route path="/event/active" element={<PrivateRoute><EventActiveList /></PrivateRoute>} />
              <Route path="/event/:event" element={<PrivateRoute><EventSingle /></PrivateRoute>} />

              {/* Broadcast/Stream */}
              <Route path="/broadcast" element={<Navigate to="/schedule/events" replace />} />
              <Route path="/broadcast/my" element={<PrivateRoute><BroadcastMy /></PrivateRoute>} />
              <Route path="/broadcast/:stream" element={<PrivateRoute noHeader={true}><BroadcastSingle /></PrivateRoute>} />

              {/* Experience Composer Page */}
              <Route path="/ec" element={<PublicRoute ecPage={true}><ComposerPage /></PublicRoute>} />

              {/* Watch Page */}
              <Route path="/watch/:stream" element={<PublicRoute><WatchSingle /></PublicRoute>} />

              {/* Orgs */}
              <Route path="/orgs/list" element={<PrivateRoute><OrgList /></PrivateRoute>} />

              {/* Hosts */}
              <Route path="/user/list" element={<PrivateRoute><UserList /></PrivateRoute>} />
              <Route path="/moderation" element={<PrivateRoute><Moderation /></PrivateRoute>} />
              <Route path="/moderation/list" element={<PrivateRoute><ModerationList /></PrivateRoute>} />

              {/* Uploaded Videos */}
              <Route path="/media" element={<PrivateRoute><Media /></PrivateRoute>} />
              <Route path="/videos/:id" element={<PrivateRoute><ViewUploadedMedia type="video"/></PrivateRoute>} />
              <Route path="/images/:id" element={<PrivateRoute><ViewUploadedMedia type="image"/></PrivateRoute>} />
              <Route path="/overlays/:id" element={<PrivateRoute><ViewUploadedMedia type="overlay"/></PrivateRoute>} />
              <Route path="/backgrounds/:id" element={<PrivateRoute><ViewUploadedMedia type="background"/></PrivateRoute>} />

              {/* Exceptions */}
              <Route path="*" element={<PublicRoute><Exception404 /></PublicRoute>} />

              {/* Add this new route for the Clips page */}
              <Route path="/clips" element={<PrivateRoute><ClipsPage /></PrivateRoute>} />

            </Routes>
          </Suspense>
        </Router>
      </ThemeContext.Provider>
      </ConfigProvider>
    </LoaderStore>
  );
}

export default App;

